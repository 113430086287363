require('@rails/ujs').start()

// 初期化処理
import Initializer    from 'lib/initializer'
import Carousel       from 'front/carousel'
import Utilities      from 'lib/utilities'
import InfiniteScroll from 'front/infinite_scroll'

Initializer.loadWebfont();
Carousel.start();
Initializer.lazy();
Initializer.navigation();
Initializer.drawerLeft();
Initializer.drawerRight();
Initializer.smoothScroll();
Initializer.analytics();
//Initializer.turbolinksDebug();

// turbolinksでのリクエスト時に発動
//document.addEventListener('turbolinks:load', () => {
//
//  Carousel.start();
//  Initializer.lazy();
//  Initializer.drawerLeft();
//  Initializer.drawerRight();
//  Initializer.smoothScroll();
//
//  // instagram埋め込みturbolink対策
//  if (window.instgrm) window.instgrm.Embeds.process();
//});
